import {IconButton, baseTheme} from '@x5-react-uikit/core'
import {Add, Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import UsersCombobox from './UsersCombobox'
import {Roles} from '@root/constants'
import {Button} from 'ui-kit'
import {Task} from '@root/features/tasks/types'
import {UseFormReturn} from 'react-hook-form'
import {AssigneeFormValue} from '../types'
import React from 'react'
import {ExtractConstValues} from '@root/core/helperTypes'
import {match} from 'ts-pattern'

const {colors} = baseTheme

type Props = {
  form: UseFormReturn<AssigneeFormValue>
  inputWidth: number
  currentRole: ExtractConstValues<typeof Roles>
}

// TODO: IT NEEDS TO BE REFACTORED
const usersWithAccessComboboxList: React.FC<Props> = ({form, inputWidth, currentRole}) => {
  console.log('currentRole', currentRole)
  const roles = match(currentRole)
    .with(Roles.BUSINESS, () => [Roles.BUSINESS])
    .with(Roles.SPECIALIST, () => [Roles.SPECIALIST, Roles.BUSINESS])
    .with(Roles.MANAGER, () => [Roles.BUSINESS, Roles.MANAGER, Roles.SPECIALIST])
    .with(Roles.ADMIN, () => [Roles.BUSINESS, Roles.MANAGER, Roles.SPECIALIST, Roles.ADMIN])
    .otherwise(() => {
      throw new Error('Out of range')
    })

  const usersWithAccess: Task['usersWithAccess'] = form.getValues('usersWithAccess')
  return (
    <FlexboxColumn sx={{gap: '8px'}}>
      {usersWithAccess?.length > 0 ? (
        usersWithAccess.map((selectedPerformer, selectedPerformerIndex) => (
          <FlexboxRow key={selectedPerformer?.id} sx={{gap: '8px', alignItems: 'center'}}>
            <UsersCombobox
              exludedUsers={form
                .getValues('usersWithAccess')
                .filter((p) => !!p && p !== selectedPerformer)}
              label="Доступ к заявке"
              required={selectedPerformerIndex === 0}
              roles={roles}
              selectedUser={selectedPerformer}
              widthPx={inputWidth}
              onChange={(event, newPerformer) => {
                form.setValue(`usersWithAccess.${selectedPerformerIndex}`, newPerformer, {
                  shouldDirty: true,
                })
              }}
              onClear={() =>
                form.setValue(`usersWithAccess.${selectedPerformerIndex}`, undefined, {
                  shouldDirty: true,
                })
              }
            />
            {usersWithAccess.filter(Boolean).length > 0 && (
              <IconButton
                IconComponent={<DelIcon color={colors.grey[60]} />}
                size="small"
                variant="text"
                onClick={() =>
                  form.setValue(
                    'usersWithAccess',
                    form
                      .getValues('usersWithAccess')
                      .filter((_, performerIndex) => performerIndex !== selectedPerformerIndex),
                    {shouldDirty: true}
                  )
                }
              />
            )}
          </FlexboxRow>
        ))
      ) : (
        <UsersCombobox
          label="Доступ к заявке"
          required={false}
          roles={roles}
          selectedUser={null}
          widthPx={inputWidth}
          onChange={(event, performer) => {
            form.setValue('usersWithAccess.0', performer, {shouldDirty: true})
          }}
          onClear={null}
        />
      )}
      <Button
        startIcon={<Add size="small" />}
        style={{width: 'fit-content', paddingLeft: 0}}
        variant="text"
        onClick={() =>
          form.setValue('usersWithAccess', [
            ...(usersWithAccess?.length > 0 ? usersWithAccess : [undefined]),
            undefined,
          ])
        }
      >
        Добавить еще
      </Button>
    </FlexboxColumn>
  )
}

export default usersWithAccessComboboxList
