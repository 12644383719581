import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  MouseEvent,
  ChangeEvent,
  useMemo,
  memo,
} from 'react'
import {Input} from '../Input'
import {DropdownInnerRefMethods} from '../Dropdown'
import {SelectBase} from './SelectBase'
import {DoubleAdornment} from './SelectAdornment'
import {SelectProps} from './types'
import {getQaAttribute} from '../utils'
import {filterOptions, getDifferentProps} from './helpers'
import {StyledSelect, selectClasses} from './styles'
import {usePreviousValue} from 'shared'

const ComboboxInner: FC<SelectProps> = (props) => {
  const {
    value: valueFromProps,
    size,
    disabled = false,
    isOpen: isOpenInit = false,
    qa = 'combobox',
    onOpen,
    onChange,
    onFilterChange,
    onClearClick,
    onBlur,
  } = props
  const value = valueFromProps ?? ''
  const {inputProps, selectBaseProps, commonProps} = getDifferentProps(props)
  const getQA = getQaAttribute(qa)
  const dropdownRef = useRef<DropdownInnerRefMethods>()
  const inputRef = useRef<HTMLInputElement>(null)

  const readonlyOptions = useMemo(
    () => props.options.filter((option) => option.name && option.value),
    [props.options]
  )

  const [options, setOptions] = useState(readonlyOptions)
  const [isOpened, setOpened] = useState(isOpenInit && !disabled)
  const [inputValue, setInputValue] = useState('')

  const prevInputValue = usePreviousValue(inputValue)

  useEffect(() => {
    setOptions(readonlyOptions)
  }, [readonlyOptions])

  useEffect(() => {
    if (isOpened) {
      dropdownRef.current.open()
    } else {
      dropdownRef.current.close()
    }
  }, [isOpened])

  useEffect(() => {
    if (value != null) {
      const found = options.find((option) => option.value === value)

      if (found) {
        setInputValue(found?.name || '')
      }
    }
  }, [options, value])

  const handleDropdownChange = useCallback(
    (opened: boolean) => {
      setOpened(opened)
      if (onOpen) {
        onOpen(opened)
      }
    },
    [onOpen]
  )

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {target} = event

      setOpened(true)

      if (target.value != prevInputValue) {
        onChange(event, null)
      }

      setInputValue(target.value)

      if (!onFilterChange) {
        setOptions(filterOptions(readonlyOptions, target.value))

        return
      }

      onFilterChange(event)
    },
    [onChange, onFilterChange, prevInputValue, readonlyOptions]
  )

  const handleClearClick = useCallback(
    (event: MouseEvent<SVGElement>) => {
      if (disabled) return
      event.stopPropagation()
      setInputValue('')
      setOptions(readonlyOptions)

      if (onClearClick == null) {
        onChange(null, null)
        return
      }

      onClearClick(event)
    },
    [disabled, onChange, onClearClick, readonlyOptions]
  )

  const handleInputClick = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      if (disabled) {
        event.stopPropagation()
      }
    },
    [disabled]
  )

  const innerOnChange = useCallback(
    (event, option) => {
      setOpened(false)

      onChange(event, option)
    },
    [onChange]
  )

  const ComboboxInput = (
    <Input
      {...commonProps}
      endAdornment={
        <DoubleAdornment
          clearable={!!inputValue.length}
          opened={isOpened}
          size={size}
          onClick={handleClearClick}
        />
      }
      inputProps={{className: selectClasses.input}}
      inputRef={inputRef}
      qa={getQA('input', disabled && 'disabled')}
      value={inputValue}
      onBlur={onBlur}
      onChange={handleSearchChange}
      onClick={handleInputClick}
      {...inputProps}
    />
  )

  return (
    <StyledSelect opened={isOpened}>
      <SelectBase
        {...commonProps}
        highlighted
        action={ComboboxInput}
        getQA={getQA}
        innerRef={dropdownRef}
        inputValue={inputValue}
        multiple={false}
        options={options}
        value={value}
        onChange={innerOnChange}
        onDropdownChange={handleDropdownChange}
        {...selectBaseProps}
      />
    </StyledSelect>
  )
}

export const Combobox = memo(ComboboxInner)

export default Combobox
