import useNotify from '@root/hooks/useNotify'
import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'
import {useCallback, useEffect, useState} from 'react'
import useUpdateReport from './useUpdateReport'
import {match} from 'ts-pattern'
import {useIdleTimer} from 'react-idle-timer'
import {useNavigate} from 'react-router-dom'

type Params = {
  updated: boolean
  reportId: string
  discardChanges: boolean
  isDirty: boolean
  getValues: () => any
}

export const useSaveReportBeforeTeardown = ({
  updated,
  reportId,
  isDirty,
  discardChanges,
  getValues,
}: Params) => {
  const {notifySuccess, notifyError} = useNotify()
  const [idle, setIdle] = useState(false)
  const navigate = useNavigate()

  useIdleTimer({
    timeout: 60 * 1000,
    onIdle: () => {
      setIdle(true)
    },
  })

  const {update} = useUpdateReport()
  const saveAction = useCallback(async () => {
    if (updated) {
      return
    }

    const updateData = getValues()
    try {
      await update(updateData, reportId)
      notifySuccess('Изменения сохранены')
    } catch (e) {
      console.error(e)
      notifyError('Не удалось автоматически сохранить отчет')
    }
  }, [getValues, notifyError, notifySuccess, reportId, update, updated])

  let shouldBlock = false

  if (!discardChanges) {
    shouldBlock = isDirty && !updated
  }

  if (!idle) {
    shouldBlock = false
  }

  useSaveChangesBeforeTeardown({shouldBlock, saveAction})

  useEffect(() => {
    if (!idle) {
      return
    }

    notifyError('Вы переведены в раздел отчетов из-за бездействия.')
    navigate('/reports')
  }, [idle, notifyError, navigate])
}
