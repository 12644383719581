import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {isEmpty, isString} from 'lodash'
import {Result, result} from '@root/core/result'
import {isServerError, matchServerErrorToString, ServerError} from '@root/core/serverResultParser'
import useNotify from '@root/hooks/useNotify'
import {match, P} from 'ts-pattern'
import {Box, Button, Link, Typography} from 'ui-kit'
import Section from '@root/components/Section'
import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'

import {
  UpdateComparativeReportPayload,
  useGetByIdComparativeReportQuery,
  useLockComparativeMutation,
  useUnlockComparativeMutation,
  useUpdateComparativeReportMutation,
} from '@root/redux/api/report/comparativeReportApi'

import {Download} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {formatDateRuLocale} from '@root/utils'
import RouterLink from '@root/components/RouterLink'
import {
  CancelEditModal,
  ComparisonData,
  ComparisonTable,
  DeleteReportModal,
  EditableField,
  NotFilledTypography,
} from './components'
import {Resolver, useForm} from 'react-hook-form'
import type {FormData} from './types'
import {ComparativeReportContextProvider} from './context'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {useCanEditReport} from '@root/features/reports/hooks/useCanEditReport'

import {useLockReport} from '@root/features/reports/hooks/useLockReport'
import {useEditReportGuard} from '@root/features/reports/hooks/useEditReportGuard'
import {useIsMounted} from '@root/hooks/useIsMounted'

import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {urlValidator} from '@root/validators'
import {useDownloadComparativeReportPdf} from '@root/features/reports/hooks/useDownloadComparativeReportPdf'
import {EditorTooltip} from '@root/features/reports/components/EditorTooltip'

import {filesWithCommentValidator} from '@root/features/fileUpload/filesWithCommentValidator'
import {
  mapFileWithCommentsToDto,
  mapFileWithCommentsToForm,
} from '@root/components/inputs/FileWithCommentArray'

import {ComparativeReport} from '@root/features/reports/types'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {useIdleTimer} from 'react-idle-timer'

enum ErrorType {
  INVALID_URL_PARAM,
  SERVER_ERROR,
}

type ValidationError =
  | {type: ErrorType.INVALID_URL_PARAM}
  | {type: ErrorType.SERVER_ERROR; body: ServerError}

type UrlParams = {
  reportId: string
}

function parseUrlParams(reportId: unknown): Result<null, ValidationError> {
  if (isString(reportId)) {
    return result.ok(null)
  }

  return result.error({type: ErrorType.INVALID_URL_PARAM})
}

function isServerErrorMapped(isError: boolean, error: unknown): Result<null, ValidationError> {
  return result.mapError(isServerError(isError, error), (err) => ({
    type: ErrorType.SERVER_ERROR,
    body: err,
  }))
}

function validate(reportId: unknown, isError: boolean, error: unknown) {
  return result.bind(parseUrlParams(reportId), (_) => isServerErrorMapped(isError, error))
}

type Props = {
  edit?: boolean
}

const requiredMessage = 'Обязательное поле'
const impUploadFailedMessage = 'Изображение не удалось загрузить на сервер.'

const schema = yup.object({
  name: yup.string().required(requiredMessage),
  kaitenLink: urlValidator(false).nullable().optional(),
  reportData: yup
    .object({
      files: filesWithCommentValidator(requiredMessage, impUploadFailedMessage)
        .max(5)
        .optional()
        .nullable(),
      graphs: yup
        .array()
        .of(
          yup.object({
            graphName: yup.string().required(requiredMessage),
            graphUrl: urlValidator(true),
            comment: yup.string().optional().nullable(),
          })
        )
        .max(5)
        .optional()
        .nullable(),
      commonData: yup
        .object({
          statisticsComment: yup.string().optional().nullable(),
          goal: yup.string().optional().nullable(),
          conclusion: yup.string().optional().nullable(),
          limitations: yup.string().optional().nullable(),
        })
        .required(),
      dynamicFields: yup
        .array()
        .of(
          yup.object({
            label: yup.string().required(requiredMessage),
            values: yup
              .object({
                comment: yup
                  .object({
                    value: yup.string().required(requiredMessage),
                  })
                  .required(),
              })
              .required(),
          })
        )
        .optional()
        .nullable(),
    })
    .required(),
})

function mapDtoToForm(dto: ComparativeReport): FormData {
  const files = dto.data.files

  return {
    name: dto.name,
    kaitenLink: dto.kaitenLink,
    reportData: {
      ...dto?.data,
      files: mapFileWithCommentsToForm(files),
      graphs: isEmpty(dto.data.graphs) ? [{}] : dto.data.graphs,
    },
  }
}

function mapFormToRequestDto(form: FormData): UpdateComparativeReportPayload['body'] {
  return {
    ...form,
    reportData: {
      ...form.reportData,
      files: mapFileWithCommentsToDto(form.reportData.files),
    },
  }
}

const ComparativeViewEditPage: FC<Props> = ({edit = false}) => {
  const {reportId} = useParams<UrlParams>()
  const navigate = useNavigate()

  const {
    data: comparativeReport,
    error,
    isError,
    isLoading,
  } = useGetByIdComparativeReportQuery(reportId)

  const {data: userInfo} = useGetUserinfoQuery()

  const isMountedRef = useIsMounted()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [cancelEditOpen, setCancelEditOpen] = useState(false)
  const [alreadyUpdated, setAlreadyUpdated] = useState(false)
  const [discardChanges, setDiscardChanges] = useState(false)
  const [idle, setIdle] = useState(false)
  const [updateComparativeReport, {isLoading: isUpdating}] = useUpdateComparativeReportMutation()
  const reportPdf = useDownloadComparativeReportPdf(comparativeReport)
  const {notifySuccess, notifyError} = useNotify()

  useIdleTimer({
    timeout: 60 * 1000,
    onIdle: () => {
      if (edit) {
        setIdle(true)
      }
    },
  })

  useEffect(() => {
    const validationResult = validate(reportId, isError, error)

    if (!result.isError(validationResult)) {
      return
    }

    const errMessage = match(validationResult.value)
      .with({type: ErrorType.SERVER_ERROR}, ({body}) => matchServerErrorToString(body))
      .with({type: ErrorType.INVALID_URL_PARAM}, () => 'Invalid url param "reportId"')
      .otherwise(() => 'Unknown error')

    console.error('validation error', validationResult.value)
    notifyError(errMessage)
    navigate('/reports')
  }, [error, isError, navigate, notifyError, reportId])

  const {register, reset, getValues, handleSubmit, formState, control, setValue} =
    useForm<FormData>({
      resolver: yupResolver(schema) as unknown as Resolver<FormData>,
    })

  useEffect(() => {
    if (formState.isDirty) {
      setAlreadyUpdated(false)
    }
  }, [formState.isDirty])

  const canEdit = useCanEditReport(comparativeReport, false)

  useEffect(() => {
    if (comparativeReport == null) {
      return
    }

    reset(mapDtoToForm(comparativeReport))
  }, [comparativeReport, reset])

  const [sendLockMut] = useLockComparativeMutation()
  const [sendUnlockMut] = useUnlockComparativeMutation()

  const sendLock = useCallback((id: string) => sendLockMut(id).unwrap(), [sendLockMut])
  const sendUnlock = useCallback((id: string) => sendUnlockMut(id).unwrap(), [sendUnlockMut])

  useEditReportGuard(comparativeReport, !edit)

  useLockReport({
    fallbackUrl: '/reports',
    sendLock,
    sendUnlock,
    report: comparativeReport,
    disable: !edit,
  })

  const saveAction = useCallback(
    async (values: FormData) => {
      if (alreadyUpdated) {
        return
      }

      try {
        await updateComparativeReport({reportId, body: mapFormToRequestDto(values)}).unwrap()
        notifySuccess('Изменения сохранены')
      } catch (e) {
        console.error('update failed, reason', e)
        notifyError('Не удалось автоматически сохранить отчет')
      }
    },
    [alreadyUpdated, notifyError, notifySuccess, reportId, updateComparativeReport]
  )

  const teardownSaveAction = useCallback(async () => {
    if (!edit) {
      return
    }

    return await saveAction(getValues())
  }, [edit, getValues, saveAction])

  const shouldBlock = match(edit)
    .with(true, () => {
      if (discardChanges || idle) {
        return false
      }

      return formState.isDirty && !alreadyUpdated
    })
    .otherwise(() => false)

  useSaveChangesBeforeTeardown({shouldBlock, saveAction: teardownSaveAction})

  useEffect(() => {
    if (!idle) {
      return
    }

    notifyError('Вы переведены в раздел отчетов из-за бездействия.')
    navigate('/reports')
  }, [idle, notifyError, navigate])

  const submitCallback = useMemo(
    () =>
      handleSubmit(
        (values) => {
          if (!edit) {
            console.warn('Form submitted in view mode.')
            return
          }

          saveAction(values).then(() => {
            if (isMountedRef.current) {
              setAlreadyUpdated(true)

              navigate('/reports')
            }
          })
        },
        (errors) => {
          console.log(errors, getValues())
          notifyError('В форме содержатся ошибки')
        }
      ),
    [handleSubmit, edit, saveAction, isMountedRef, navigate, getValues, notifyError]
  )

  const handleDeleteClick = useCallback(() => {
    if (edit) {
      return
    }

    setDeleteOpen(true)
  }, [edit])

  const handleDeleteModalClose = useCallback(() => {
    if (isMountedRef.current) {
      setDeleteOpen(false)
    }
  }, [isMountedRef])

  useEffect(() => {
    if (!discardChanges) {
      return
    }

    navigate(`/reports/comparative/${reportId}`)
  }, [discardChanges, navigate, reportId])

  const confirmLeaveFromPage = useCallback(() => {
    setDiscardChanges(true)
  }, [])

  const handleCancelEditButtonClick = useCallback(() => {
    if (formState.isDirty) {
      setCancelEditOpen(true)
    } else {
      setDiscardChanges(true)
    }
  }, [formState.isDirty])

  const handleLeaveModalClose = useCallback(() => {
    if (isMountedRef.current) {
      setCancelEditOpen(false)
    }
  }, [isMountedRef])

  if (isLoading || comparativeReport == null) {
    return <LoaderFrame />
  }

  const title = match(edit)
    .with(true, () => 'Редактирование сравнительного отчета')
    .with(false, () => 'Сформированный сравнительный отчет')
    .exhaustive()

  const editButton = (
    <Button disabled={!canEdit} variant="secondary">
      Редактировать
    </Button>
  )

  const deleteButton = (
    <Button disabled={!canEdit} variant="secondary" onClick={handleDeleteClick}>
      Удалить
    </Button>
  )

  const showEditButtons = !userInfo.isBusiness

  return (
    <ComparativeReportContextProvider
      value={{
        reportId,
        isEdit: edit,
        comparativeReport: comparativeReport,
        register,
        control,
        setValue,
        getValues,
      }}
    >
      <DeleteReportModal open={deleteOpen} reportId={reportId} onClose={handleDeleteModalClose} />
      <CancelEditModal
        isOpen={cancelEditOpen}
        onClose={handleLeaveModalClose}
        onSubmit={confirmLeaveFromPage}
      />

      <Box sx={{mx: 'x8', width: '100%', minWidth: '1200px'}}>
        <form onSubmit={submitCallback}>
          <Breadcrumbs
            routes={[
              {to: '/tasks', label: 'Главная'},
              {to: '/reports', label: 'Отчеты портала'},
            ]}
          />
          <Box style={{display: 'flex', justifyContent: 'space-between'}} sx={{mb: 'x12'}}>
            <Typography variant="h2">{title}</Typography>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              {match(edit)
                .with(false, () => (
                  <>
                    {showEditButtons && (
                      <>
                        {match(canEdit)
                          .with(true, () => deleteButton)
                          .otherwise(() => (
                            <EditorTooltip
                              email={
                                comparativeReport.currentEditor?.email ??
                                comparativeReport.currentEditor?.name
                              }
                            >
                              {deleteButton}
                            </EditorTooltip>
                          ))}
                        {match(canEdit)
                          .with(true, () => (
                            <RouterLink noUnderline to={`/reports/comparative/${reportId}/update`}>
                              {editButton}
                            </RouterLink>
                          ))
                          .otherwise(() => (
                            <EditorTooltip
                              email={
                                comparativeReport.currentEditor?.email ??
                                comparativeReport.currentEditor?.name
                              }
                            >
                              {editButton}
                            </EditorTooltip>
                          ))}
                      </>
                    )}

                    <Button
                      disabled={reportPdf.disabled}
                      loading={reportPdf.loading}
                      startIcon={<Download size="small" />}
                      variant="primary"
                      onClick={reportPdf.download}
                    >
                      Скачать отчёт
                    </Button>
                  </>
                ))
                .with(true, () => (
                  <>
                    <Button variant="secondary" onClick={handleCancelEditButtonClick}>
                      Отмена
                    </Button>

                    <Button
                      disabled={isUpdating}
                      loading={isUpdating}
                      type="submit"
                      variant="primary"
                    >
                      Сохранить изменения
                    </Button>
                  </>
                ))
                .exhaustive()}
            </Box>
          </Box>

          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow style={{gap: '24px'}}>
              <Section sx={{m: 'x0', my: 'x0', p: 'x12', flex: 1}}>
                <FlexboxColumn style={{gap: '24px'}}>
                  <Typography variant="h3">Данные отчета</Typography>
                  <Box>
                    <EditableField
                      value={
                        <>
                          <Box>
                            <Typography>Наименование отчета</Typography>
                          </Box>
                          <Box>
                            <Typography>{comparativeReport.name}</Typography>
                          </Box>
                        </>
                      }
                    >
                      <FormInputText<FormData>
                        required
                        control={control}
                        label="Наименование отчета"
                        name="name"
                        width="100%"
                      />
                    </EditableField>
                  </Box>
                </FlexboxColumn>
              </Section>
              <Section sx={{m: 'x0', my: 'x0', p: 'x12', flex: 1}}>
                <FlexboxColumn style={{gap: '24px'}}>
                  <Typography variant="h3">Даты и задачи</Typography>
                  <FlexboxRow style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                    <FlexboxColumn style={{flex: 1}}>
                      <Typography>Дата формирования отчета</Typography>
                      <Typography>{formatDateRuLocale(comparativeReport.createdAt)}</Typography>
                    </FlexboxColumn>
                    <FlexboxColumn style={{flex: 1}}>
                      <EditableField
                        value={
                          <>
                            <Typography>Задача в Kaiten</Typography>
                            {match(comparativeReport.kaitenLink)
                              .with(P.nullish, () => (
                                <NotFilledTypography>Не заполнено</NotFilledTypography>
                              ))
                              .otherwise((x) => (
                                <Link href={x} rel="noopener noreferrer" target="_blank">
                                  Перейти к связанным задачам
                                </Link>
                              ))}
                          </>
                        }
                      >
                        <FormInputText<FormData>
                          control={control}
                          label="Ссылка на Kaiten"
                          name="kaitenLink"
                          width="100%"
                        />
                      </EditableField>
                    </FlexboxColumn>
                  </FlexboxRow>
                </FlexboxColumn>
              </Section>
            </FlexboxRow>

            <ComparisonTable reports={comparativeReport.reportsToCompare} />

            <ComparisonData />
          </FlexboxColumn>
        </form>
      </Box>
    </ComparativeReportContextProvider>
  )
}

export default ComparativeViewEditPage
