import {AppTableState} from '@root/components/appTable'
import {FilterCollectorFilter} from '@root/components/kit'
import {Roles, TaskStatuses} from '@root/constants'
import {ExtractConstValues} from '@root/core/helperTypes'
import {TaskListItem} from '@root/features/tasks/types'
import {components, FilterItemType, SortRequestType} from '@root/openapi'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {GetTasksByFilterPayload} from '@root/redux/api/tasksApi'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty, merge} from 'lodash'
import {TaskRoleTabKind, TaskStatusTabKind} from '../types'

type FilterItem = components['schemas']['FilterItem']

function getExpressionForTab(
  currentTab: TaskRoleTabKind,
  userEmail: string,
  userRole: ExtractConstValues<typeof Roles>
): GetTasksByFilterPayload['filters'] {
  const emailLikeExpression = {value: userEmail as any, type: FilterItemType.LIKE}
  if (userRole === Roles.BUSINESS) return {'creator.email|stakeholders.email': emailLikeExpression}
  else if (userRole === Roles.SPECIALIST) return {'performers.email': emailLikeExpression}

  switch (currentTab) {
    case null:
    case undefined:
      return {}
    case TaskRoleTabKind.all:
      return {
        status: {
          value: [TaskStatuses.DRAFT, TaskStatuses.WITHDRAWN] as any,
          type: FilterItemType.NOT_EQUAL,
        },
      }
    case TaskRoleTabKind.mine:
      return {
        manager: emailLikeExpression,
      }
    case TaskRoleTabKind.notAssigned:
      return {
        manager: {
          value: null as any,
          type: FilterItemType.IS_NULL,
        },
      }
    default:
      throw new Error('Out of range')
  }
}

function getExpressionForStatusTab(statusTab: TaskStatusTabKind): Record<string, FilterItem> {
  let value: any = [TaskStatuses.DRAFT, TaskStatuses.WITHDRAWN]
  let type = FilterItemType.EQUAL

  switch (statusTab) {
    case TaskStatusTabKind.active:
      type = FilterItemType.NOT_EQUAL
      break
    case TaskStatusTabKind.draft:
      break
    case TaskStatusTabKind.withdrawn:
      value = [TaskStatuses.WITHDRAWN]
      break
    default:
      throw new Error('Out of range')
  }
  return {status: {value, type}}
}

function sortValueMapper(tableFormat: ExtractConstValues<typeof sortDirections>): SortRequestType {
  switch (tableFormat) {
    case 'asc':
      return SortRequestType.ASC
    case 'desc':
      return SortRequestType.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof TaskListItem): string {
  switch (field) {
    case 'creator':
      return 'creator.email'
    default:
      return field
  }
}

// why not sortNameMapper? because we sort by different entity properties
function getExpressionForField(field: FilterCollectorFilter): [string, FilterItem] {
  const fieldName = field.name as keyof TaskListItem
  const value = field.value as any

  switch (fieldName) {
    case 'system':
      return ['system.id', {value, type: FilterItemType.EQUAL}]
    case 'number':
      return ['number', {value, type: FilterItemType.EQUAL}]
    case 'status':
      return ['status', {value, type: FilterItemType.EQUAL}]
    case 'CPP':
      return ['CPP', {value, type: FilterItemType.EQUAL}]
    case 'startAt':
      return ['startAt', {value, type: FilterItemType.EQUAL}]
    case 'factFinishedDate':
      return ['factFinishedDate', {value, type: FilterItemType.EQUAL}]
    case 'finishedAt':
      return ['finishedAt', {value, type: FilterItemType.EQUAL}]
    case 'creator':
      return ['creator', {value, type: FilterItemType.LIKE}]
    case 'performers':
      return ['performers.email', {value, type: FilterItemType.LIKE}]
    case 'manager':
      return ['manager', {value, type: FilterItemType.LIKE}]
    default:
      throw new Error(`Out of range, ${fieldName}`)
  }
}

export function fieldMapper(
  tableState: AppTableState<TaskListItem>,
  statusTab: TaskStatusTabKind,
  userRole: ExtractConstValues<typeof Roles>,
  userEmail?: string
): GetTasksByFilterPayload {
  const tabExpression = getExpressionForTab(tableState.currentTab, userEmail, userRole)

  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, expression]: [string, FilterItem]) => !isEmpty(expression.value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  const sortExpressions: GetLaunchesPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  if (isEmpty(filterExpressions['system.id'])) {
    // TODO: KLUDGE
    sortExpressions.unshift({
      value: 'system',
      type: SortRequestType.DESC,
    })
  }

  const filters = {
    filters: {
      ...tabExpression,
      ...filterExpressions,
    },
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }

  const statusTabExpression = getExpressionForStatusTab(statusTab)
  if (statusTab === TaskStatusTabKind.draft) {
    ;(filters.filters as any).status = statusTabExpression.status
    // merge(filters.filters, getExpressionForStatusTab(statusTab)) // doesnt work
  } else {
    merge(statusTabExpression, filters.filters)
  }

  return filters
}
