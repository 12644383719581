import {FC, useMemo} from 'react'
import type {MetaRendererProps, TableMeta, TableRowObject} from './types'

import {AppTableColumn, AppTable} from '@root/components/appTable'

type DynamicTableRow = Record<keyof TableMeta['columns'], string> & {id: string}

function toDynamicTableRow(row: TableRowObject, index: number): DynamicTableRow {
  const dynamicRow = row.cells.reduce((acc, next) => {
    acc[next.columnId] = next.values.text.value
    return acc
  }, {} as DynamicTableRow)

  dynamicRow.id = index.toString()

  return dynamicRow
}

function createColumns(meta: TableMeta): AppTableColumn<DynamicTableRow>[] {
  return Object.values(meta.columns).map<AppTableColumn<DynamicTableRow>>((x) => ({
    title: x.label,
    dataIndex: x.id,
    valueRenderType: 'textWithUrl',
  }))
}

function getId(row: DynamicTableRow) {
  return row.id
}

const Table: FC<MetaRendererProps<TableMeta>> = ({meta, value, fallback}) => {
  const rows = useMemo(() => value.rows.map(toDynamicTableRow), [value])
  const columns = useMemo(() => createColumns(meta), [meta])

  if (value == null) {
    return <>{fallback}</>
  }

  return <AppTable columns={columns} data={rows} getId={getId} />
}

export default Table
