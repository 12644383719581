import {Box, Typography, baseTheme} from '@x5-react-uikit/core'
import {ChevronDown, ChevronUp, Done} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import useNotify from '@root/hooks/useNotify'
import {isEmpty} from 'lodash'
import QuestionRow from '@root/pages/tasks/CreateTaskPage/components/QuestionRow'
import useUpdateFormData from '@root/pages/tasks/CreateTaskPage/hooks/useUpdateFormData'
import {
  getFieldComponent,
  getFieldReadonlyComponent,
} from '@root/pages/tasks/CreateTaskPage/utils/componentUtils'
import {
  InputTypes,
  checkInputFilled,
  getBlankInputs,
  getOrCreateFieldData,
} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {FC, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Button} from 'ui-kit'
import {UseTaskPageForm} from '../hooks/useTaskPageForm'
import {createLogger} from '@root/logger'

const log = createLogger('Dynamic forms | FieldAccordion')

const {colors} = baseTheme

const ROOT_REGISTER = 'formData'

const RequiredRule = {type: 'custom', message: 'Поле не может быть пустым'}
const RequiredCheckboxOrCommentRule = {
  type: 'custom',
  message:
    'Это обязательное поле. Пожалуйста, выберите цели, задачи нагрузочного тестирования из списка или оставьте комментарий в поле ниже.',
}

type Props = {
  fieldMeta?: any
  fieldIndex?: number
  stepIndex?: number
  form?: UseTaskPageForm['form']
  title?: string
  error?: boolean
  message?: string
  readonly?: boolean
}

const FieldAccordeon: FC<Props> = ({
  children,
  fieldMeta,
  fieldIndex,
  stepIndex,
  form,
  title,
  error,
  message,
  readonly,
}) => {
  const {taskId} = useParams()
  const {notifyError, notifyWarning, notifySuccess} = useNotify()
  const [editing, setEditing] = useState(false)

  const [valuesBeforeEditing, setValuesBefore] = useState(null)
  const [questionBeforeEditing, setQuestionBeforeEditing] = useState(null)

  const baseRegister = `formData.${stepIndex}.fields.${fieldIndex}.values` as const

  const restoreRegister = `formData.${stepIndex}.fields.${fieldIndex}` as const

  const questionRegister = `${ROOT_REGISTER}.${stepIndex}.fields.${fieldIndex}.question` as const

  const handleSetEditing = () => {
    if (!expanded) setExpanded(true)

    setValuesBefore(form.getValues(restoreRegister))
    setQuestionBeforeEditing(form.getValues(questionRegister))
    setEditing(true)
  }
  const [expanded, setExpanded] = useState(false)

  const handleCancelEditing = () => {
    setEditing(false)
    if (!form) return

    form.clearErrors(baseRegister)

    if (valuesBeforeEditing != null) {
      form.setValue(restoreRegister, valuesBeforeEditing)
      form.clearErrors(restoreRegister)
    }

    if (questionBeforeEditing != null) {
      form.setValue(questionRegister, questionBeforeEditing)
      form.clearErrors(questionRegister)
    }

    const defaultValue =
      form.formState.defaultValues[ROOT_REGISTER][stepIndex].fields[fieldIndex].values

    form.resetField(baseRegister, {defaultValue})
  }

  const {updateFormData, isUpdating: isFormDataUpdating} = useUpdateFormData()
  // TODO: Нужен рефакторинг хендлера, в текущем виде сложно поддерживать
  const handleSave = form?.handleSubmit(
    ({formData: allFields}: any) => {
      if (isEmpty(form.formState.dirtyFields[ROOT_REGISTER])) {
        log.debug('Empty dirty fields. Can not save form')
        return
      }

      const fieldValues = allFields[stepIndex].fields[fieldIndex].values
      if (fieldMeta.required) {
        const errors: [string, object][] = [] // [register, rule][]
        const answer = allFields[stepIndex].fields[fieldIndex].question
        let fieldMetaValues = fieldMeta.values
        if (fieldMeta.question) {
          fieldMetaValues = fieldMeta.question[answer].values
        }

        Object.keys(fieldValues).forEach((inputType) => {
          const inputValues = fieldValues[inputType]
          if (!inputValues) return
          if (!fieldMetaValues.find((meta) => meta.type === inputType)) return

          let inputMeta
          switch (inputType) {
            case InputTypes.text:
              inputValues.forEach((inputObj, inputObjIndex) => {
                inputMeta = fieldMetaValues.find((meta) => meta.id === inputObj.id)
                if (!checkInputFilled(inputObj, inputMeta))
                  errors.push([`${baseRegister}.text.${inputObjIndex}.value`, RequiredRule])
              })
              break
            case InputTypes.comment: {
              // Случай, когда field состоит из массива чекбоксов и комментария, обрабатываем отдельно.
              // Логика следующая: если ни один из чекбоксов не активен, то комментарий должен быть заполнен.
              const blankComment = getBlankInputs(
                allFields[stepIndex].fields[fieldIndex],
                fieldMeta
              )?.[0]
              if (blankComment)
                errors.push([`${baseRegister}.comment.value`, RequiredCheckboxOrCommentRule])
              break
            }
            case InputTypes.dateInput:
              inputValues.forEach((inputObj, inputObjIndex) => {
                inputMeta = fieldMetaValues.find((meta) => meta.id === inputObj.id)
                if (!checkInputFilled(inputObj, inputMeta))
                  errors.push([`${baseRegister}.dateInput.${inputObjIndex}.value`, RequiredRule])
              })
              break
            case InputTypes.list:
              inputValues.forEach((listObj, listObjIndex) => {
                const listMeta = fieldMetaValues.find((meta) => meta.id === listObj.id)
                const elementRegister = `${baseRegister}.list.${listObjIndex}.elements`
                listObj.elements.forEach((listElement, listElementIndex) => {
                  Object.entries(listElement.values).forEach(
                    ([listInputType, listInputs]: [string, any[]]) => {
                      if (listInputType === InputTypes.text) {
                        listInputs.forEach((inputObj, inputObjIndex) => {
                          inputMeta = listMeta.elements.find((el) => el.id === inputObj.id)
                          if (!checkInputFilled(inputObj, inputMeta)) {
                            errors.push([
                              `${elementRegister}.${listElementIndex}.values.text.${inputObjIndex}.value`,
                              RequiredRule,
                            ])
                          }
                        })
                      }
                      // if (listInputType === InputTypes.table) {}
                    }
                  )
                })
                // if (elementInvalid)
                //   form.setError(listMeta.id, RequiredRule);
              })
              break
            case InputTypes.table:
              inputValues.forEach((inputObj, inputObjIndex) => {
                inputMeta = fieldMetaValues.find((meta) => meta.id === inputObj.id)
                if (!checkInputFilled(inputObj, inputMeta))
                  errors.push([`${baseRegister}.table.${inputObjIndex}.rows`, RequiredRule])
              })
              break
            default:
              break
          }
        })
        if (errors.length > 0) {
          errors.forEach((inputErrorData) => {
            form.setError(inputErrorData[0] as any, inputErrorData[1])
          })
          notifyWarning('Имеются незаполненные поля.')
          return
        }
      }

      // Обновляем только текущее поле.
      // Остальные изменённые поля сохраняем с дефолтными значениями.
      const currentFieldUpdatedSteps = {...form.formState.defaultValues[ROOT_REGISTER]}
      currentFieldUpdatedSteps[stepIndex].fields[fieldIndex] =
        allFields[stepIndex].fields[fieldIndex]
      updateFormData({
        steps: Object.values(currentFieldUpdatedSteps),
        currentStepId: allFields[stepIndex].number,
        updatedFields: [fieldMeta.id],
        onSuccess: () => {
          notifySuccess('Изменения сохранены')
          setEditing(false)
        },
      })
    },
    (err) => {
      console.log(err)
      notifyError()
    }
  )

  let content = children
  if (!content) {
    if (form && editing) {
      const {fieldValue, fieldIndex} = getOrCreateFieldData(
        fieldMeta,
        form.getValues(ROOT_REGISTER),
        ROOT_REGISTER
      )
      const inputsMeta = fieldMeta.question
        ? fieldMeta.question[fieldValue.question].values
        : fieldMeta.values
      content = (
        <>
          {fieldMeta.id === 'deadlines' ? (
            <FlexboxRow sx={{flexWrap: 'wrap', boxSizing: 'border-box', mb: 'x12'} as any}>
              {inputsMeta.map((inputMeta, i) => {
                const indexes = {field: fieldIndex, step: stepIndex}
                return (
                  <Box key={i} sx={{flex: '0 0 50%', boxSizing: 'border-box', pt: 'x12'} as any}>
                    {getFieldComponent({
                      inputMeta,
                      form,
                      indexes,
                      fieldValue,
                      rootRegister: ROOT_REGISTER,
                    })}
                  </Box>
                )
              })}
            </FlexboxRow>
          ) : (
            <FlexboxColumn sx={{gap: '24px'}}>
              <QuestionRow
                fieldIndex={fieldIndex}
                fieldMeta={fieldMeta}
                fieldValue={fieldValue}
                form={form}
                rootRegister={ROOT_REGISTER}
                stepIndex={stepIndex}
              />
              {inputsMeta.map((inputMeta) => {
                const indexes = {field: fieldIndex, step: stepIndex}

                return getFieldComponent({
                  inputMeta,
                  form,
                  indexes,
                  fieldValue,
                  rootRegister: ROOT_REGISTER,
                })
              })}
            </FlexboxColumn>
          )}
        </>
      )
    } else if (form && !editing)
      content = getFieldReadonlyComponent({
        fieldMeta,
        form,
        commentTitle: null,
        fileBaseUrl: `/v1/tasks/${taskId}/form/files/${fieldMeta.id}`,
        baseRegister: ROOT_REGISTER,
      })
  }

  return (
    <>
      <FlexboxRow
        sx={{
          py: 'x16',
          // px: 'x12',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <FlexboxColumn>
            <FlexboxRow
              sx={{cursor: 'pointer', userSelect: 'none'} as any}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? <ChevronUp /> : <ChevronDown />}
              <Typography variant="h3">{title}</Typography>
            </FlexboxRow>
            {error && (
              <Typography style={{color: colors.error}} variant="p2">
                {error}
              </Typography>
            )}
            {message && (
              <Typography style={{color: colors.grey[60]}} variant="p2">
                {message}
              </Typography>
            )}
          </FlexboxColumn>
          {!readonly &&
            (!editing ? (
              <Button variant="text" onClick={handleSetEditing}>
                Редактировать пункт
              </Button>
            ) : (
              <FlexboxRow sx={{gap: '16px'}}>
                <Button size="medium" variant="outlined" onClick={handleCancelEditing}>
                  Отмена
                </Button>
                <Button
                  disabled={isFormDataUpdating}
                  loading={isFormDataUpdating}
                  size="medium"
                  startIcon={<Done size="small" />}
                  style={{textWrap: 'nowrap'}}
                  onClick={handleSave}
                >
                  Сохранить изменения
                </Button>
              </FlexboxRow>
            ))}
        </FlexboxRow>
      </FlexboxRow>
      {expanded && <Box sx={{mb: 'x16'}}>{content}</Box>}
    </>
  )
}

export default FieldAccordeon
