import {Buffer} from 'buffer'
import {aggregateResults, Result, result} from '@root/core/result'
import {getErrorFromQuery, ServerError} from '@root/core/serverResultParser'
import {
  ComparativeReport,
  ComparativeReportWithImages,
  FileWithCommentImageData,
  Graph,
  GraphWithImageData,
  Report,
  ReportWithStatisticsImages,
} from './types'

import {axiosClient} from '@root/axiosClient'
import {fileServiceApi} from '@root/api/fileServiceApi'
import {FileWithComment} from '@root/features/fileUpload'
import {match} from 'ts-pattern'

async function downloadRequest(fileName: string, comparative: boolean) {
  const response = await axiosClient.get(
    `/reports/${comparative ? 'comparative/' : ''}statistics-images/download/${fileName}`,
    {
      responseType: 'arraybuffer',
    }
  )

  return Buffer.from(response.data, 'binary')
}

function getImageSize<TBuffer extends Buffer | ArrayBuffer>(buffer: TBuffer) {
  return new Promise<{width: number; height: number; imageBuffer: TBuffer}>((resolve) => {
    const blob = new Blob([buffer], {type: 'image/jpeg'})
    const img = new Image()
    const blobUrl = URL.createObjectURL(blob)
    img.src = blobUrl

    img.onload = function () {
      URL.revokeObjectURL(blobUrl)
      resolve({
        width: img.width,
        height: img.height,
        imageBuffer: buffer,
      })
    }
  })
}

export async function downloadGrafanaImages(
  reportId: string,
  requestFn: (reportId: string) => Promise<Graph[]>,
  comparative: boolean
): Promise<Result<GraphWithImageData[], ServerError>> {
  try {
    const graphs = await requestFn(reportId)

    const promises = graphs.map((x) =>
      downloadRequest(x.graphUrl, comparative)
        .then(getImageSize)
        .then(({imageBuffer, height, width}) => {
          return {
            imageSize: {
              width: width,
              height: height,
            },
            imageBuffer,
            comment: x.comment,
            graphName: x.graphName,
          }
        })
    )

    return result.ok(await Promise.all(promises))
  } catch (err) {
    return getErrorFromQuery(err)
  }
}

export const grafanaDisplayErrorMessage =
  'На текущий момент Grafana недоступна, не удалось сформировать графики. Пожалуйста, попробуйте позднее'

export const image404DisplayErrorMessage =
  'Не удалось скачать картинки для отчета - файлы отсутствуют на сервере'

export function getComparativeFilesErrorMessage(type: 'grafana' | 'files') {
  return match(type)
    .with('files', () => image404DisplayErrorMessage)
    .with('grafana', () => grafanaDisplayErrorMessage)
    .exhaustive()
}

export async function enhanceReportWithImages(
  report: Report,
  requestFn: (reportId: string) => Promise<Graph[]>
): Promise<Result<ReportWithStatisticsImages, ServerError>> {
  return result.map(await downloadGrafanaImages(report.id, requestFn, false), (images) => {
    const reportWithImages: ReportWithStatisticsImages = {
      ...report,
      statistics: {
        graphs: images,
      },
    }

    return reportWithImages
  })
}

async function downloadFileWithComments(
  arr: FileWithComment[]
): Promise<Result<FileWithCommentImageData[], ServerError[]>> {
  const resList = await Promise.all(
    arr.map((x) =>
      fileServiceApi
        .getProtectedImageResult<ArrayBuffer>(x.url, 'arraybuffer')
        .then(async (res) => {
          if (result.isError(res)) {
            return res
          }

          const {height, width, imageBuffer} = await getImageSize(Buffer.from(res.value as any))

          return result.ok({
            ...x,
            imageSize: {height, width},
            imageBuffer,
          })
        })
    )
  )
  return aggregateResults(resList)
}

export async function enhanceComparativeReportWithImages(
  comparativeReport: ComparativeReport,
  requestFn: (comparativeReportId: string) => Promise<Graph[]>
): Promise<Result<ComparativeReportWithImages, {data: ServerError[]; type: 'files' | 'grafana'}>> {
  const [filesResult, graphsResult] = await Promise.all([
    downloadFileWithComments(comparativeReport?.data?.files ?? []),
    downloadGrafanaImages(comparativeReport.id, requestFn, true),
  ])

  if (result.isError(filesResult)) {
    return result.mapError(filesResult, (data) => ({data, type: 'files'}))
  }

  if (result.isError(graphsResult)) {
    return result.mapError(graphsResult, (x) => ({data: [x], type: 'grafana'}))
  }

  const files = filesResult.value
  const graphs = graphsResult.value

  const enhanced: ComparativeReportWithImages = {
    ...comparativeReport,
    data: {
      ...comparativeReport.data,
      files,
      graphs,
    },
  }

  return result.ok(enhanced)
}
