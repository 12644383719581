import {FilterItemType} from '@root/openapi'
import {Metadata} from './types'

export const VariablesName = 'variables'

export const UniqueVarNameRule = {
  type: 'custom',
  message: 'Название переменной должно быть уникально',
}
export const UniqueTemplateNameRule = {
  type: 'custom',
  message: 'Название шаблона должно быть уникальным',
}

export const getDuplicatedVarRegisters = (variables) => {
  const duplicats = []
  variables.forEach((variable, variableIndex) => {
    if (variables.filter((v) => v.key === variable.key).length > 1)
      duplicats.push(`${VariablesName}.${variableIndex}.key`)
  })
  return duplicats
}

export const getTasksFilter = (systemId: string) => ({
  sort: {createdAt: 'DESC'},
  filters: {'system.id': {value: systemId, type: FilterItemType.EQUAL}},
})

export const isMetadataFilled = (metadata: Metadata) => {
  return (
    !!metadata &&
    !!metadata.templateId &&
    !!metadata.gitlabBranch &&
    !!metadata.gitlabProjectId &&
    !!metadata.gitlabProjectName &&
    !!metadata.gitlabRepoUrl
  )
}
