import {CSSProperties, type FC, useCallback, useMemo, useState} from 'react'
import Section from '@root/components/Section'
import {Tab, TabList, Tabs} from 'ui-kit'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import MetaRenderer from '@root/features/dynamicForm'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {MetaObject} from '@root/features/dynamicForm/meta'
import {ValueObject} from '@root/features/dynamicForm/value'

import {useComparativeReportContext} from '../context'
import {Accordion} from './Accordion'
import {EditableField} from './EditableField'

import {DynamicFields} from './DynamicFields'
import {ReportSwitch} from './ReportSwitch'
import {NotFilledTypography} from './common'
import type {FormData} from '../types'
import VariablesList from '@root/components/VariablesList'
import {StatisticsTab} from '@root/pages/report/ComparativeViewEditPage/components/StatisticsTab'

enum TabKind {
  Data,
  Statistics,
  Variables,
}

const tabs = [
  {
    value: TabKind.Data,
    title: 'Данные',
  },
  {
    value: TabKind.Statistics,
    title: 'Статистика',
  },
  {
    value: TabKind.Variables,
    title: 'Переменные теста',
  },
]

const EmptyText = <NotFilledTypography>Не заполнен</NotFilledTypography>

function showWhenEquals<TVal>(value: TVal, when: TVal): CSSProperties {
  return {display: value === when ? 'block' : 'none'}
}

export const ComparisonData: FC = () => {
  const [currentTab, setTab] = useState(TabKind.Data)
  const {comparativeReport, control, isEdit} = useComparativeReportContext()
  const reportData = useMemo(() => comparativeReport.data.commonData, [comparativeReport])

  const handleTabChange = useCallback((_event: unknown, tab: TabKind) => {
    setTab(tab)
  }, [])

  return (
    <Section sx={{my: 'x0', m: 'x0', p: 'x12'}}>
      <FlexboxColumn style={{gap: '24px'}}>
        <Tabs style={{width: '100%'}} value={currentTab} onChange={handleTabChange}>
          <TabList holderStyles={{width: '100%'}} style={{width: '100%'}}>
            {tabs.map(({value, title}) => (
              <Tab key={value} label={title} value={value} />
            ))}
          </TabList>
        </Tabs>

        <div style={showWhenEquals(currentTab, TabKind.Data)}>
          <FlexboxColumn style={{gap: '24px'}}>
            <Accordion defaultOpen name="Цель тестирования">
              <EditableField value={reportData?.goal}>
                <FormInputText<FormData>
                  textarea
                  control={control}
                  label="Комментарий"
                  name="reportData.commonData.goal"
                  width="520px"
                />
              </EditableField>
            </Accordion>

            <Divider />

            <Accordion defaultOpen name="Выводы">
              <EditableField value={reportData?.conclusion}>
                <FormInputText<FormData>
                  textarea
                  control={control}
                  label="Комментарий"
                  name="reportData.commonData.conclusion"
                  width="520px"
                />
              </EditableField>
            </Accordion>

            <Divider />

            <Accordion defaultOpen name="Ограничения тестирования">
              <EditableField value={reportData?.limitations}>
                <FormInputText<FormData>
                  textarea
                  control={control}
                  label="Комментарий"
                  name="reportData.commonData.limitations"
                  width="520px"
                />
              </EditableField>
            </Accordion>

            <Divider />

            <Accordion defaultOpen name="Конфигурация среды">
              <FlexboxColumn style={{gap: '24px'}}>
                <div>
                  <ReportSwitch>
                    {(report) => (
                      <MetaRenderer
                        fallback={EmptyText}
                        meta={
                          ReportCommonFieldsMeta.configuration.values[0] as unknown as MetaObject
                        }
                        value={
                          report.reportData.commonFields.find((x) => x.id === 'configuration')
                            .values.table[0] as unknown as ValueObject
                        }
                      />
                    )}
                  </ReportSwitch>
                </div>

                <EditableField value={reportData?.configurationComment}>
                  <FormInputText<FormData>
                    textarea
                    control={control}
                    label="Комментарий"
                    name="reportData.commonData.configurationComment"
                    width="520px"
                  />
                </EditableField>
              </FlexboxColumn>
            </Accordion>

            <DynamicFields />
          </FlexboxColumn>
        </div>

        <div style={showWhenEquals(currentTab, TabKind.Statistics)}>
          {currentTab === TabKind.Statistics && <StatisticsTab />}
        </div>

        <div style={showWhenEquals(currentTab, TabKind.Variables)}>
          <ReportSwitch>{(report) => <VariablesList variables={report.variables} />}</ReportSwitch>
        </div>
      </FlexboxColumn>
    </Section>
  )
}
