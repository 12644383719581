import type {Report} from '@root/features/reports/types'
import {FC, useMemo} from 'react'
import {AppTable, AppTableColumn} from '@root/components/appTable'
import RouterLink from '@root/components/RouterLink'
import {formatDateTimeRuLocale} from '@root/utils'
import Section from '@root/components/Section'
import {Box, Typography} from 'ui-kit'
import {
  ReportToCompareRow,
  reportToCompareRow,
  getId,
} from '@root/features/reports/comparisonTableConstants'

const columns: AppTableColumn<ReportToCompareRow>[] = [
  {
    dataIndex: 'id',
    title: 'Номер',
    width: 150,
    renderCell: (_, {number}) => `Отчет №${number}`,
  },
  {
    dataIndex: 'name',
    title: 'Отчет',
    // width: '13.33333%', // 160 / 1200
    renderCell: (_, {name, id}) => (
      <RouterLink target="_blank" to={`/reports/${id}`}>
        {name}
      </RouterLink>
    ),
  },
  {
    dataIndex: 'task',
    title: 'Заявка',
    // width: '28.83333%', // 346 / 1200
    renderCell: (_, {task: {id, number}}) => <RouterLink to={`/tasks/${id}`}>#{number}</RouterLink>,
  },
  {
    dataIndex: 'test',
    title: 'Тест',
    // width: '28.83333%', // 346 / 1200
    // TODO: THERE IS NO ROUTE TO A TEST LAUNCH
    renderCell: (_, {test: {number}}) => `ID ${number}`,
  },
  {
    dataIndex: 'testFinishedAt',
    title: 'Дата и время завершения теста',
    // width: '28.83333%', // 346 / 1200
    renderCell: (_, {testFinishedAt}) => formatDateTimeRuLocale(testFinishedAt),
  },
  {
    dataIndex: 'template',
    title: 'Шаблон переменных',
    renderCell: (_, {template: {name}}) => name,
  },
]

type Props = {
  reports: Report[]
}

export const ComparisonTable: FC<Props> = ({reports}) => {
  const data = useMemo(() => reports.map((x, i) => reportToCompareRow(x, i + 1)), [reports])

  return (
    <Section sx={{m: 'x0', my: 'x0', p: 'x0', pb: 'x20'}}>
      <Box sx={{p: 'x12'}}>
        <Typography variant="h3">Сравниваемые отчеты</Typography>
      </Box>
      <AppTable
        columns={columns}
        components={{Cell: {style: {padding: '12px'}}, HeadCell: {style: {padding: '12px'}}}}
        data={data}
        getId={getId}
      />
    </Section>
  )
}
